import React, {useState} from "react";
import {Button, Tooltip, Icon, Divider, Drawer} from "antd";

const AmzSeeMore = ({data}) => {
    const [visible, setVisible] = useState(false);
    const handleClose = () => {
        setVisible(false);
    }

    const handleShow = () => {
        setVisible(true);
    }

    return (
        <>
            <Divider type="vertical" />
            <Tooltip title="See more">
                <Button onClick={handleShow} size="small">
                    <Icon type="eye" />
                </Button>
            </Tooltip>

            <Drawer
                width={400}
                title="Detail"
                placement="right"
                closable={false}
                onClose={handleClose}
                visible={visible}
            >
                <ul style={{
                    margin: "0px",
                    padding: "0px",
                    fontSize: "16px"
                }}>
                    <li><strong>Asin: </strong> {data.amz_asin !== "" ? data.amz_asin : "updating..."}</li>
                    <li><strong>Sku: </strong> {data.amz_sku !== "" ? data.amz_sku : "updating..."}</li>
                    <li><strong>Status:</strong> {data.amz_step_status === 'complete' ? <strong style={{
                        color: "green",
                        textTransform: "capitalize",
                        fontWeight: "500"
                    }}>
                        {data.amz_step_status}
                    </strong> : <strong style={{
                        color: "#ff9100",
                        textTransform: "capitalize",
                        fontWeight: "500"
                    }}>
                        {data.amz_step_status}
                    </strong>}</li>
                </ul>
            </Drawer>
        </>
    )
}

export default AmzSeeMore;
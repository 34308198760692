import React, { Component } from 'react'
import gql from '../../api/gql'
import { notification, Form, Select, Input } from 'antd'
import _ from 'lodash'

class AddTracking extends Component {
  state={
    loading: false,
    carriers:[]
  }
  onSubmit = e => {
    const {order} = this.props
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({loading: true})
        let query = `mutation addTracking($order_id: Int!, $carrier_id: Int!, $tracking_code: String!){
      addTracking(order_id: $order_id, carrier_id: $carrier_id, tracking_code: $tracking_code)
    }`

        gql.request(query, {
          order_id: order.id,
          carrier_id: parseInt(values.carrier_id),
          tracking_code: values.tracking_code
        }).then(() => {
          this.setState({loading: false})
          notification.success({
            message: 'Tracking added'
          })
          this.props.onClose();
        }).catch(err => {
          const {onUnLoading} = this.props;
          if(onUnLoading) onUnLoading();
          this.setState({ loading: false })
          notification.error({
            message: _.get(err, '[0].message')
          })
        })
      }
    })
  }

  loadOrderCarriers = (order_id)=>{
    let query = `query order{
      order(id: ${order_id}){
        id,
        carrier{
          id
          name
        }
        variant{
          product_type{
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
      }
    }`
    this.setState({ loadingMenuIcon: "loading" })
    gql.request(query).then(res => {
      this.setState({
        carriers: res.order.variant.product_type.product_type_carriers,
        loadingMenuIcon: "down"
      });
      //console.log("carriers", this.state.carriers, "order-id", order_id)
    }).catch(err => {
      this.setState({
        loadingMenuIcon: "down"
      })
      notification.error({
        message: _.get(err, '[0].message')
      })
    })
  }
  
  componentDidMount(){
    const { order, onChangeDisabledAddTracking } = this.props
    if(order){
      this.loadOrderCarriers(order.id)
    }
    const initValue = order.tracking ? order.tracking.tracking_code : null;
    if(!initValue){
      if(onChangeDisabledAddTracking) onChangeDisabledAddTracking(true);
    }else{
      if(onChangeDisabledAddTracking) onChangeDisabledAddTracking(false);
    }
  }

  handleChangeCode = (e) => {
    const value = e.target.value;
    const {onChangeDisabledAddTracking} = this.props;
    if(!onChangeDisabledAddTracking) return;
    if(!value){
      onChangeDisabledAddTracking(true);
    }else{
      onChangeDisabledAddTracking(false);
    }

  }

  render() {
    const { order } = this.props
    const { getFieldDecorator } = this.props.form
    //const carriers = order.variant.product_type.product_type_carriers
    const {carriers}=this.state
    return (
      <div>
        <Form id="add-tracking" onSubmit={this.onSubmit}>
          <Form.Item>
            {getFieldDecorator("carrier_id", {
              initialValue: order.carrier ? order.carrier.id : null,
              rules: [{
                required: true,
                message: "Please select carrier!"
              }]
            })(
              <Select style={{ width: 200, marginRight: 10 }} placeholder="Please select Carrier">
                {carriers && carriers.map((carrier) => (
                  <Select.Option key={carrier.carrier.id} value={carrier.carrier.id}>{carrier.carrier.name}</Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("tracking_code", {
              initialValue: order.tracking ? order.tracking.tracking_code : null,
              rules: [{
                required: true,
                message: "Please input tracking code!"
              }]
            })(
                <Input onInput={this.handleChangeCode} placeholder="Tracking code" />
            )}
          </Form.Item>
        </Form>

      </div>
    )
  }
}

export default Form.create({ name: "add-tracking" })(AddTracking);
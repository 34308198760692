import React, {Component} from 'react'
import { connect } from 'react-redux'
import Products from '../../components/Products'
import ProductsFilter from '../../components/products/ProductsFilter'
import { Row, Col } from 'antd';
import ProductToolbar from '../../components/products/ProductToolbar';
import styled from 'styled-components';
const Container =styled.div`
  @media only screen and (min-width: 768px) {
    .btn-add{
      text-align: right
    }
  }
  @media only screen and (max-width: 768px) {
    .p-filter{
      display: flex;
      flex-direction: column-reverse;
      .btn-add{
        text-align: right
      }
    }
  }
`
class ProductsPage extends Component{
  constructor(props){
    super(props)
    var state = {fetching: false,
      sites: [],
      productTypes: [],
      filter: {
        search: "",
        site_ids: null,
        product_type_ids: null,
      }
    }
    const {currentUser, currentSite} = props
    if (currentUser.roles.map(role => role.name).includes('Seller')) {
      state.filter.site_ids = currentSite ? [currentSite.id] : []
    }
    this.state = state
  }
  render() {
    const {currentSite, currentUser} = this.props
    const allowProductAction = currentUser.roles.map(role => role.name).includes('Administrator') || (currentSite && currentSite.type!=="offline")
    return (
      <Container>
        {
          currentUser.roles.map(role => role.name).includes('Administrator') ? 
        <Row
          type="flex"
          style={{ width: "calc(100%)"}}
        >
            <ProductsFilter onChange={filter => this.setState({ filter})} {...this.props}/>
        </Row>:
          <Row
          style={{ width: "calc(100%)"}}
          className="p-filter"
        >
          <Col
            md={20}
            style={{ display: "flex", alignItems: "center"}}
          >
            <ProductsFilter onChange={filter => this.setState({ filter})} {...this.props}/>
          </Col>
          <Col md={4} style={{textAlign:"left", marginBottom:10}}>
            <div className="btn-add">
              {allowProductAction && <ProductToolbar currentSite={currentSite} />}
            </div>
          </Col>
        </Row>
        }
        <Products filter={this.state.filter}  {...this.props} />
      </Container>
    )
  }
}

export default connect(
  state => ({
    currentSite: state.app.currentSite
  })
)(ProductsPage)
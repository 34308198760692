import React from 'react'
import { Button, Upload, Modal, Table, Icon, notification, Alert } from 'antd'
import Papa from "papaparse"
import gql from '../../api/gql'
import _ from 'lodash'

export default class ImportTrackings extends React.Component {
  state = {
    trackings: [],
    loading: false,
    showTrackings: false,
    hasFailed: false,
    downloading: false,
    reloadData: false
  }

  handleUpload = file => {
    this.setState({
      showTrackings: true
    })
    const reader = new FileReader()
    reader.onload = evt => {
      const bstr = evt.target.result
      const parsed = Papa.parse(bstr, { skipEmptyLines: true, })
      this.setState({
        data: parsed.data
      }, () => this.loadSheet())
    }
    reader.readAsBinaryString(file)
    return false
  }

  loadSheet = () => {
    const { data } = this.state
    let trackings = []
    data.splice(0, 1)
    //console.log("data", data)

    for (var i = 0; i < data.length; i++) {
      if (data[i].length) {
        trackings.push({
          order_id: !!data[i][0] ? data[i][0].toString() : '',
          carrier_code: !!data[i][1] ? data[i][1].toString() : '',
          tracking_code: !!data[i][2] ? data[i][2].toString() : '',
        })
      }
    }
    this.setState({
      trackings: trackings
    })
  }

  importTrackings = e => {
    e.preventDefault()
    this.setState({ loading: true })
    const query = `mutation importTracking($input:[TrackingInput!]!){
      importTracking(input: $input){
        id
        order_id
        carrier_code
        tracking_code
      }
    }`

    gql.request(query, {
      input: this.state.trackings
    }).then(res => {
      const failedTrackings = _.xorBy(this.state.trackings, res.importTracking, "tracking_code")
      if(failedTrackings.length>0){
          if ( failedTrackings.length === this.state.trackings.length) {
            this.setState({trackings: failedTrackings, loading: false, hasFailed: true, reloadData: false})
          }
          else {
            this.setState({trackings: failedTrackings, loading: false, hasFailed: true, reloadData: true})
          }
      }else{
        notification['success']({
          message: 'Imported successful.'
        })
        this.props.onChange()
        this.setState({ loading: false, showTrackings: false })
      }

    }).catch(err => {
      notification['error']({
        message: _.get(err, '[0].message')
      })
      this.setState({ loading: false })
    })
  }
  downloadFailedTracking = e => {
    e.preventDefault()
    this.setState({
      downloading: true
    })
    let query = `mutation downloadTracking($trackings: [TrackingInput!]!){
      downloadTracking(trackings: $trackings)
    }`
    
    gql.request(query, {
      trackings: this.state.trackings
    }).then(res => {
      window.location = res.downloadTracking
      this.setState({
        downloading: false
      })
    }).catch(err => {
      this.setState({
        downloading: false
      })
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }

  handleCancel = () => {
    const {reloadData} = this.state
    if (reloadData === true) {
      this.setState({ hasFailed:false, showTrackings: false, trackings: [] })
      this.props.onChange()
    }
    else 
    {this.setState({ hasFailed:false, showTrackings: false, trackings: [] })}
  };
  handleSubmit = (e) => {
    this.importTrackings(e)
  };

  render() {
    const { trackings, showTrackings, loading, downloading, hasFailed } = this.state

    return (
      <div>
        <Upload
          accept=".csv"
          showUploadList={false}
          beforeUpload={this.handleUpload}
          multiple={false}
          customRequest={file => { }}
        >
          <Button icon="import">Import Trackings</Button>
        </Upload>
        <Modal maskClosable={false} visible={showTrackings}
          confirmLoading={loading}
          //onOk={this.importTrackings}
          onClose={() => this.setState({showTrackings: false})}
          width="800px"
          title="Import Trackings"
          onCancel={this.handleCancel}
          footer={[
            <Button key="download" onClick={this.downloadFailedTracking} loading={downloading} disabled={!hasFailed}>
              Download
            </Button>,
            <Button key="cancel" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
              Submit
            </Button>,
          ]}

        >
        {hasFailed&&<Alert type="error" message="Following tracking(s) are failed to import"/>}
          {trackings.length ? <Table dataSource={trackings} columns={[
            {
              title: 'Order ID',
              dataIndex: 'order_id'
            },
            {
              title: 'Carrier Code',
              dataIndex: 'carrier_code'
            },
            {
              title: 'Tracking Code',
              dataIndex: 'tracking_code'
            }
          ]} rowKey={(row, index) => index} /> : <Icon type="loading" />}
        </Modal>
      </div>
    )
  }
}
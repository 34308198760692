import React, { Component } from 'react'
import { Tabs, Form, Checkbox, Button, Select, message, Card } from 'antd'
import gql from '../../api/gql'
import TextArea from 'antd/lib/input/TextArea'
import _ from 'lodash'
import BrandingSettings from '../../components/sites/BrandingSettings';

// const settings = [
//   {
//     key: "order_received",
//     title: "Order received"
//   },
//   {
//     key: "order_start_producing",
//     title: "Order start producing"
//   },
//   {
//     key: "order_shipped",
//     title: "Order shipped"
//   }
// ]

class SiteSettings extends Component {
  state = {
    loading: false,
    siteId: 0,
    menukey: "1",
    settings: {
      branding_packing_slip_front: "0",
      branding_packing_slip_back: "0",
      branding_insert_card_outside: "0",
      branding_insert_card_inside: "0",
      branding_sticker_front: "0",
      branding_custom_logo: "0",
      order_received_enable: false,
      order_received_after: 1,
      order_received_message: '',
      order_producing_enable: false,
      order_producing_message: '',
      order_shipping_enable: false,
      order_shipping_message: '',
      fulfill_role: false
    }
  }

  componentDidMount() {
    this.loadSiteSettings()
  }

  loadSiteSettings() {
    const { match: { params: { id } } } = this.props
    this.setState({ site_id: id })
    let query = `query getSiteSettings{
      getSiteSettings(site_id: ${id}){
        branding_packing_slip_front
        branding_packing_slip_back
        branding_insert_card_outside
        branding_insert_card_inside
        branding_sticker_front
        branding_custom_logo
        order_received_enable
        order_received_after
        order_received_message
        order_producing_enable
        order_producing_message
        order_shipping_enable
        order_shipping_message
        fulfill_role
      }
    }`

    gql.request(query).then(res => {
      this.setState({
        settings: res.getSiteSettings
      })
      console.log("res", res)
    })
  }

  handleClickMenu = (e) => {
    this.setState({ menukey: e.key })
  }

  onChangeChecked = (key, checked) => {
    const { settings } = this.state
    settings[key] = checked.target.checked
    this.setState({ settings: { ...settings } })
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      const { match: { params: { id } } } = this.props
      const {settings} = this.state
      if (!err) {
        const mutation = `mutation updateSiteSettings($site_id: Int!, $settings: SiteSettingsInput!){
          updateSiteSettings(site_id: $site_id, settings: $settings){
            branding_packing_slip_front
            branding_packing_slip_back
            branding_insert_card_outside
            branding_insert_card_inside
            branding_sticker_front
            branding_custom_logo
            order_received_enable
            order_received_after
            order_received_message
            order_producing_enable
            order_producing_message
            order_shipping_enable
            order_shipping_message
            fulfill_role
          }
        }`
        this.setState({ loading: true })
        gql.request(mutation, { site_id: id, settings: {
          ...values, 
          branding_packing_slip_front: settings.branding_packing_slip_front,
          branding_packing_slip_back: settings.branding_packing_slip_back,
          branding_insert_card_outside: settings.branding_insert_card_outside,
          branding_insert_card_inside: settings.branding_insert_card_inside,
          branding_sticker_front: settings.branding_sticker_front,
          branding_custom_logo: settings.branding_custom_logo
        } })
          .then(res => {
            this.setState({ loading: false })
            message["success"]("Site settings has been updated")
          })
          .catch(err => {
            message["error"](_.get(err, "[0].message"))
          })
      }
    })
  }
  render() {
    const { settings, site_id } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.onSubmit}>
        <Tabs defaultActiveKey="email">
          <Tabs.TabPane tab="Email notification" key="email">
            <Tabs tabPosition='left'>
              <Tabs.TabPane tab="Order received" key="order_received">
                <Card title="Order received settings">
                  <Form.Item>
                    {
                      getFieldDecorator('order_received_enable', {
                        valuePropName: 'checked',
                        initialValue: settings.order_received_enable
                      })(<Checkbox onChange={ck => this.onChangeChecked('order_received_enable', ck)}>Enable</Checkbox>)
                    }
                  </Form.Item>
                  <Form.Item label="After">
                    {
                      getFieldDecorator('order_received_after', {
                        initialValue: settings.order_received_after
                      })(<Select disabled={!settings.order_received_enable} >
                        <Select.Option value={1}>1 day</Select.Option>
                        <Select.Option value={2}>2 day</Select.Option>
                        <Select.Option value={3}>3 day</Select.Option>
                      </Select>)
                    }
                  </Form.Item>
                  <Form.Item label="Message">
                    {
                      getFieldDecorator('order_received_message', {
                        initialValue: settings.order_received_message
                      })(<TextArea disabled={!settings.order_received_enable} />)
                    }
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Order start producing" key="order_start_producing">
                <Card title="Order start producing settings">
                  <Form.Item>
                    {
                      getFieldDecorator('order_producing_enable', {
                        valuePropName: 'checked',
                        initialValue: settings.order_producing_enable
                      })(<Checkbox onChange={ck => this.onChangeChecked('order_producing_enable', ck)}>Enable</Checkbox>)
                    }
                  </Form.Item>
                  <Form.Item label="Message">
                    {
                      getFieldDecorator('order_producing_message', {
                        initialValue: settings.order_producing_message
                      })(<TextArea disabled={!settings.order_producing_enable} />)
                    }
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Order shipped" key="order_shipped">
                <Card title="Order shipped settings">
                  <Form.Item>
                    {
                      getFieldDecorator('order_shipping_enable', {
                        valuePropName: 'checked',
                        initialValue: settings.order_shipping_enable
                      })(<Checkbox onChange={ck => this.onChangeChecked('order_shipping_enable', ck)}>Enable</Checkbox>)
                    }
                  </Form.Item>
                  <Form.Item label="Message">
                    {
                      getFieldDecorator('order_shipping_message', {
                        initialValue: settings.order_shipping_message
                      })(<TextArea disabled={!settings.order_shipping_enable} />)
                    }
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
            </Tabs>
            <Form.Item style={{ textAlign: 'right', marginTop: 20 }}>
              <Button loading={this.state.loading} type="primary" htmlType="submit">Save</Button>
            </Form.Item>
            {/* <Row>
              <Col span={24} lg={4} style={{ display: 'flex' }}>
                <Menu
                  onClick={this.handleClickMenu}
                  style={{ width: 256, float: "left", background: "rgb(247, 249, 251)" }}
                  defaultSelectedKeys={['1']}
                  defaultOpenKeys={['sub1']}
                  mode='inline'
                >
                  <Menu.Item key="1">
                    Order received
                  </Menu.Item>
                  <Menu.Item key="2">
                    Order start producing
                  </Menu.Item>
                  <Menu.Item key="3">
                    Order shipped
                  </Menu.Item>
                </Menu>
              </Col>
              <Col span={24} lg={20} >
                <div style={menukey === "1" ? { display: "block" } : { display: "none" }}>
                  <Form.Item>
                    {
                      getFieldDecorator('order_received_enable', {
                        valuePropName: 'checked',
                        initialValue: settings.order_received_enable
                      })(<Checkbox onChange={ck => this.onChangeChecked('order_received_enable', ck)}>Enable</Checkbox>)
                    }
                  </Form.Item>
                  <Form.Item label="After">
                    {
                      getFieldDecorator('order_received_after', {
                        initialValue: settings.order_received_after
                      })(<Select disabled={!settings.order_received_enable} >
                        <Select.Option value={1}>1 day</Select.Option>
                        <Select.Option value={2}>2 day</Select.Option>
                        <Select.Option value={3}>3 day</Select.Option>
                      </Select>)
                    }
                  </Form.Item>
                  <Form.Item label="Message">
                    {
                      getFieldDecorator('order_received_message', {
                        initialValue: settings.order_received_message
                      })(<TextArea disabled={!settings.order_received_enable} />)
                    }
                  </Form.Item>
                </div>
                <div style={menukey === "2" ? { display: "block" } : { display: "none" }}>
                  <Form.Item>
                    {
                      getFieldDecorator('order_producing_enable', {
                        valuePropName: 'checked',
                        initialValue: settings.order_producing_enable
                      })(<Checkbox onChange={ck => this.onChangeChecked('order_producing_enable', ck)}>Enable</Checkbox>)
                    }
                  </Form.Item>
                  <Form.Item label="Message">
                    {
                      getFieldDecorator('order_producing_message', {
                        initialValue: settings.order_producing_message
                      })(<TextArea disabled={!settings.order_producing_enable} />)
                    }
                  </Form.Item>
                </div>
                <div style={menukey === "3" ? { display: "block" } : { display: "none" }}>
                  <Form.Item>
                    {
                      getFieldDecorator('order_shipping_enable', {
                        valuePropName: 'checked',
                        initialValue: settings.order_shipping_enable
                      })(<Checkbox onChange={ck => this.onChangeChecked('order_shipping_enable', ck)}>Enable</Checkbox>)
                    }
                  </Form.Item>
                  <Form.Item label="Message">
                    {
                      getFieldDecorator('order_shipping_message', {
                        initialValue: settings.order_shipping_message
                      })(<TextArea disabled={!settings.order_shipping_enable} />)
                    }
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button loading={this.state.loading} type="primary" htmlType="submit">Save</Button>
                </Form.Item>
              </Col>
            </Row> */}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Fulfill role" key="fulfill">
            <Card title="Fulfill role settings">
              <Form.Item>
                {getFieldDecorator('fulfill_role', {
                  valuePropName: 'checked',
                  initialValue: settings.fulfill_role
                })(<Checkbox>Remove hold time</Checkbox>)}
                <div>By default, your orders are held for 24 hours after successful payment in order to accomodate changes. By checking this box, your orders will ship one day sooner, but this will forfeit the ability to change orders.</div>
              </Form.Item>
            </Card>
            <Form.Item>
              <Button loading={this.state.loading} type="primary" htmlType="submit">Save</Button>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Premium Branding" key="branding">
            <Card title="Premium Branding Settings">
              <BrandingSettings site_id={site_id} />
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    )
  }
}

export default Form.create({ name: 'site_settings_form' })(SiteSettings)
import React, {useState} from "react";
import {Tooltip, Button, Divider, Icon, Select, Modal, message, Spin} from "antd";
import gql from "../../api/gql";
const AmzPushProduct = ({currentUser, id}) => {
    const { Option } = Select;
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState([]);
    const [site, setSite] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const handleOk = async () => {
        setLoadingSubmit(true);
        if(site === ""){
            message.info("Please select a site!!")
            setLoadingSubmit(false);
        }else {
            const query = `mutation amzPushProductToStore($store_id: Int!, $product_id: Int!) {
              amzPushProductToStore(store_id: $store_id, product_id: $product_id)
            }`
            await gql.request(query, {
                store_id: site,
                product_id: Number(id)
            }).then(res => {
                if(res.amzPushProductToStore){
                    message.success("Create a successful new product!!")
                }
                setVisible(false);
                setLoadingSubmit(false);
            }).catch(error => {
                setLoadingSubmit(false);
                message.info(error[0].message);
            });
        }
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const handleChangeSelectSite = (value) => {
        setSite(value);
    }

    const showModal = () => {
        setVisible(true);
        setLoading(true);
        const query = `query sites($filter: SiteFilter) {
          sites(filter: $filter){
            count,
            hits{
              id
              title
              domain
              platform
              status
              verified
            }
          }
        }`;
        gql.request(query, {
            filter: {
                user_id: currentUser.id
            }
        }).then(res => {
            const data = res.sites.hits;
            const newData = data.filter(e => {
                if(e.platform === 'amz' && e.verified == true){
                    return e;
                }
            });
            setData(newData);
            setLoading(false)
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });

    }


    return (
        <>
            <Modal
                title="Select Store Amazon"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loadingSubmit}
            >
                <Spin spinning={loading} tip="Loading...">
                <Select style={{ width: "100%" }} onChange={handleChangeSelectSite}>
                    {data.map((e, index) => (
                        <Option key={e.id} value={e.id}>{e.title}</Option>
                    ))}
                </Select>
                </Spin>
            </Modal>
            <Tooltip title="Push to Amazon">
                <Button onClick={showModal} size="small">
                    <Icon type="amazon" />
                </Button>
            </Tooltip>
            <Divider type="vertical" />
        </>
    )
}

export  default AmzPushProduct;
import React, { Component } from 'react'
import { notification, Icon, Avatar, Button, Drawer, Tooltip, Divider, Modal } from 'antd'
import Table from './Table'
import gql from '../api/gql'
import _ from 'lodash'
import UploadDesigns from './products/UploadDesigns'
import AmzPushProduct from "./products/AmzPushProduct";
import moment from 'moment'
import { connect } from 'react-redux'
import { history } from '../history'
import { setProduct } from "../actions"
import styled from 'styled-components'
import AmzSeeMore from "./products/AmzSeeMore";
import {get} from "immutable";
import {get as GetLodash} from 'lodash'

const _targetBlank = {
  target: '_blank',
  rel: 'noopener noreferrer'
}
const Container = styled.div`
.ant-drawer-content-wrapper {
  width: 800px !important;
}
@media only screen and (min-width: 768px) {
.ant-drawer-content-wrapper {
    width: 800px !important;
  }
}
`
class Products extends Component {
  state = {
    orders: [],
    total: 0,
    filter: {
      offset: 0,
      limit: 20,
      ...this.props.filter
    },
    selectedProducts: [],
    selectedProduct: null,
    showUploadDesign: false,
    loading: false,
    showDeleteConfirm: false,
    sitePlatform: null
  }

  componentDidMount() {
    if (this.props.currentUser.roles.map(role => role.name).includes('Seller') && this.props.currentSite) {
      this.loadProducts()
    } else if (!this.props.currentUser.roles.map(role => role.name).includes('Seller')) {
      this.loadProducts()
    }
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.state
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        page: 1,
        filter: { ...filter, offset: 0, ...this.props.filter }
      }, () => {
        if (this.props.currentUser.roles.map(role => role.name).includes('Seller') && this.props.currentSite) {
          this.loadProducts()
        } else if (!this.props.currentUser.roles.map(role => role.name).includes('Seller')) {
          this.loadProducts()
        }
      })
    }
  }

  loadProducts() {
    const query = `query products($filter: ProductFilter){
      products(filter: $filter){
        count
        hits{
          id
          origin_id
          sku
          title
          permalink
          images
          created_at
          amz_step_status
          amz_asin
          amz_sku
          is_custom_design
          site{
            title
            platform
          }
          variants{
            sku
            design_status
            product_type_id
            print_files{
              key
              title
              width
              height
              file_id
              variant_ids
            }
          }
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: this.state.filter
    }).then(res => {
      this.setState({
        products: res.products.hits || [],
        total: res.products.count,
        loading: false
      });
    });

    let isAdmin = this.props.currentUser.roles.find(el => ['Administrator', 'Seller', 'Listing'].includes(el.name))

    if(isAdmin){
      const siteID = get(this.state.filter, 'site_ids');
      if(siteID){
        const query = `query getSiteByID{
          siteById(id: ${siteID[0]}){
            id
            platform
          }
        }`;
        gql.request(query).then(res => {
          this.setState({sitePlatform: res.siteById.platform});
        }).catch(error => {
          console.log(error);
        });
      }
    }else {
      if(get(this.props.currentSite, 'platform')){
        this.setState({sitePlatform: get(this.props.currentSite, 'platform')})
      }
    }
  }

  updateProductFormSite = (id) => {
    const query = `mutation updateProduct{
      updateProductFromSite(id: "${id}")
    }`
    gql.request(query).then(res => {
      notification['success']({
        message: 'Product updated'
      })
    })
  }

  deleteProduct = (id, isDelOnStore) => {
    const query = `mutation deleteProduct{deleteProduct(product_id: ${id}, isDelOnStore: ${isDelOnStore})}`
    gql.request(query).then(res => {
      notification['success']({
        message: 'Product has been deleted'
      })
      const products = this.state.products.filter(item => item.id !== id)
      this.setState({ products: products, showDeleteConfirm: false })
    }).catch(err => {
      notification['error']({
        message: _.get(err, '[0].message')
      })
      this.setState({ showDeleteConfirm: false })
    })
  }

  handleYes = () => {
    this.deleteProduct(this.state.selectedProduct.id, true)
  }
  handleNo = () => {
    this.deleteProduct(this.state.selectedProduct.id, false)
  }
  handleCancel = () => {
    this.setState({ showDeleteConfirm: false })
  }

  render() {
    const { products, selectedProduct, showUploadDesign, total, filter, loading } = this.state
    const { currentSite, currentUser } = this.props
    const allowProductAction = currentUser.roles.map(role => role.name).includes('Administrator') || (currentSite && currentSite.type !== "offline")
    const allowCloneProduct = currentUser.roles.map(role => role.name).includes('Seller') && currentSite && currentSite.type !== "offline"
    let isAdmin = this.props.currentUser.roles.find(el => ['Administrator', 'Seller', 'Listing'].includes(el.name))
    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     this.setState({
    //       selectedProducts: selectedRows
    //     })
    //   }
    // }
    const getplatform = (id) => {
      const search = products.find(e => e.id == id);
      if(search){
        return GetLodash(search, 'site.platform', 'woocommerce');
      }else{
        return "amz";
      }
    }
    const columns = [
      {
        title: 'Origin ID',
        dataIndex: 'origin_id',
        width: 150
      },
      {
        title: 'Title',
        dataIndex: 'title',
        width: 500,
        render: (title, { id, permalink, images, sku, variants }) =>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar icon="picture" src={images ? images[0] : null} shape="square" size={60} style={{ marginRight: '10px' }} />
            <div>
              {title}
              {getplatform(id) == 'amz' ? <></> : <> 
              {allowProductAction && <a style={{marginLeft: 5}} href={permalink} {..._targetBlank}><Icon type="eye"></Icon></a>}
              </>}
              
              <br />
              {variants && <em style={{ color: '#8D8D8D' }}>{variants[0] && variants[0].sku ? variants[0].sku : null}</em>}
            </div>
          </div>
      },
      {
        title: 'Designs',
        dataIndex: 'file',
        key: 'file',
        width: 100,
        render: (files, product) => {
          const isUploadingDesign = _.find(product.variants, { design_status: false })
          return <>
          {!product.is_custom_design && <div style={{ display: 'flex', width: 200, gap: 5 }} onClick={e => {
            e.preventDefault()
            this.setState({
              selectedProduct: product,
              showUploadDesign: true,
            })
          }}>
            <Tooltip title={!isUploadingDesign ? "View design files" : "Upload design files"}>
              <Button type={!isUploadingDesign ? "primary" : "danger"} ghost icon={!isUploadingDesign ? "check" : "upload"}
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({
                    selectedProduct: product,
                    showUploadDesign: true,
                  })
                }}>
                {!isUploadingDesign ? "View" : "Upload"}
              </Button>
            </Tooltip>
          </div>
          }
          {
            product.is_custom_design && <Tooltip title="View/upload designs from order"><Button type="dashed" ghost disabled>Custom</Button></Tooltip>
          }
          </>
        }
      },
      get(this.state, 'sitePlatform') && get(this.state, 'sitePlatform') == 'amz' ? {
        title: 'Step status',
        width: 150,
        dataIndex: 'amz_step_status',
        render: (amz_step_status) => {
          if(amz_step_status === "complete"){
            return (<div style={{
            color: "green",
              textTransform: "capitalize",
              fontWeight: "500"
            }}>
              {amz_step_status}
            </div>)
          }else {
            return (<div style={{
              color: "#ff9100",
              textTransform: "capitalize",
              fontWeight: "500"
            }}>
              {amz_step_status}
            </div>)
          }
        }
      } : {},
      {
        title: 'Created Date',
       width: 150,
        dataIndex: 'created_at',
        render: (created_at) => moment(created_at).format('MMM DD, YYYY')
      },
      isAdmin ?
        {
          title: 'Actions',
          width: 200,
          dataIndex: 'id',
          key: 'actions',
          align: "right",
          render: (id, product) => <>
            <AmzPushProduct id={id} currentUser={currentUser} />
            {allowCloneProduct && 
            <>
              <Tooltip title="Clone product">
                <Button size="small"
                  onClick={(e) => {
                    e.preventDefault()
                    const product_type_ids = [...new Set(product.variants.map(el => el.product_type_id))]
                    history.push(`/products/clone/${id}/${product_type_ids.join(",")}`)
                  }}>
                  <Icon type="copy" />
                </Button>
              </Tooltip>
              <Divider type="vertical" />
            </>}
            {allowProductAction && <Tooltip title="Delete product">
              <Button size="small" onClick={(e) => {
                e.preventDefault()
                this.setState({
                  selectedProduct: product,
                  showDeleteConfirm: true,
                })
              }
              }>
                <Icon type="delete" style={{ color: "red" }} />
              </Button>
            </Tooltip>}
            {get(this.state, 'sitePlatform') && get(this.state, 'sitePlatform') == 'amz' ?
             <AmzSeeMore data={product} /> : null
            }
          </>
        } : {}
    ]
    const pagination = {
      pageSize: filter.limit,
      total: total,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState({
          filter: {
            ...filter,
            offset: (page - 1) * pageSize
          }
        }, () => this.loadProducts())
      }
    }
    return (
      <Container>
        <Table
          columns={columns}
          dataSource={products}
          // rowSelection={rowSelection} 
          pagination={pagination}
          rowKey={({ id }) => id}
          loading={loading} />
        <Drawer
          width="800px"
          title="Upload Designs"
          onClose={e => this.setState({ selectedProduct: null, showUploadDesign: false })}
          visible={showUploadDesign}
        >
          {selectedProduct && <UploadDesigns
            product_id={selectedProduct.id}
            reloadProduct={() => this.loadProducts()}
            onClose={e => this.setState({ selectedProduct: null, showUploadDesign: false })} />}
        </Drawer>
        {selectedProduct && <Modal
          visible={this.state.showDeleteConfirm}
          title="Delete Product Confirmation"
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="no" loading={loading} onClick={this.handleNo}>
              No
            </Button>,
            <Button key="yes" type="primary" loading={loading} onClick={this.handleYes}>
              Yes
            </Button>,
          ]}
        >
          <p>Do you want to delete the product on your store also?</p>
          <p style={{ color: "red" }}>Note: This action cannot be undo</p>
        </Modal>}
      </Container>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser
  }), { setProduct }
)(Products)
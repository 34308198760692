import React, { Component } from 'react'
import { Form, Input, Button, Select, notification } from 'antd'
import validator from 'validator'
import _ from 'lodash'
import gql from '../../api/gql'
import shortuuid from 'short-uuid'
import { SHOPIFY_API, API_URL, APP_URL, SHOPBASE_API } from '../../config'
class SiteAdd extends Component {
  state = {
    loading: false,
    selectedPlatform: null
  }

  handleConnect = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if(this.state.selectedPlatform === 'amz'){
          const query = `mutation authorizeApp($title: String!, $domain: String!){
            amzAuthorizeApp(title: $title, domain: $domain){
              authorization_url
              state_token
            }
          }`;
          this.setState({ loading: true });
          gql.request(query, {
            ...values
          }).then(res => {
            this.setState({ loading: false });
            const {authorization_url, state_token} = res.amzAuthorizeApp;
            window.location = authorization_url;
          })
        }else {
          const query = `mutation createSite($title: String!, $domain: String!, $platform: String!, $isConnect: Boolean!){
          createSite(title: $title, domain: $domain, platform: $platform, isConnect: $isConnect){
            id
            platform
            domain
          }
        }`
          this.setState({ loading: true })
          gql.request(query, {
            ...values,
            isConnect: this.state.selectedPlatform !== "import" ? true : false,
            domain: this.state.selectedPlatform!=="import"? values.domain.replace(/\/$/, ""):shortuuid.generate()
          }).then(res => {
            this.setState({ loading: false })
            if (this.state.selectedPlatform !== "import") {
              const { id, platform, domain } = res.createSite
              switch (platform) {
                case 'woocommerce':
                  var app_name = 'POD Fulfillment'
                  var return_url = `${APP_URL}/sites`
                  var callback_url = `${API_URL}/auth_callback/${id}`
                  var redirectUrl = `${domain}/wc-auth/v1/authorize?app_name=${app_name}&scope=read_write&user_id=1&return_url=${return_url}&callback_url=${callback_url}`
                  window.location = redirectUrl
                  break
                case 'shopify':
                  var url = domain
                  var redirect_uri = `${API_URL}/auth_callback/shopify`
                  var scopes = 'write_products,write_orders,write_fulfillments'
                  var nonce = id
                  window.location = `${url}/admin/oauth/authorize?client_id=${SHOPIFY_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`
                  break
                case 'shopbase':
                  // var url = domain
                  // var redirect_uri = `${API_URL}/auth_callback/shopbase`
                  // var scopes = 'write_products,write_orders,write_fulfillments'
                  // var nonce = id
                  window.location = `${url}/admin/oauth/authorize?client_id=${SHOPBASE_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`
                  break
                default:
                  break
              }
            } else {
              notification['success']({
                message: "Create site successful"
              })
              this.props.onSuccess()
              this.setState({ loading: false })
            }
          }).catch(err => {
            console.log(err)
            notification['error']({
              message: _.get(err, '[0].message')
            })
            this.setState({ loading: false })
          })
          return
        }
      }
    })
  }
  // updateSite(id, input) {
  //   this.setState({
  //     loading: true
  //   })
  //   gql.request({ id, input })
  //     .then(res => {
  //       this.setState({
  //         loading: false
  //       })
  //       this.props.onLoad()
  //       this.onClose()
  //     })
  // }

  render() {
    const { loading } = this.state
    const { getFieldDecorator } = this.props.form
    const platforms = [
      {
        title: 'Shopify',
        value: 'shopify'
      },
      {
        title: 'Wordpress Woocommerce',
        value: 'woocommerce'
      },
      {
        title: "Amazon",
        value: 'amz'
      }
      // {
      //   title: 'Shopbase',
      //   value: 'shopbase'
      // },
      // {
      //   title: 'Import Order Site',
      //   value: 'import'
      // }
    ]
    return (
      <Form onSubmit={this.handleConnect}>
        <Form.Item label="Site Name">
          {getFieldDecorator('title', {
            rules: [{ required: true }]
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Platform">
          {getFieldDecorator('platform', {
            rules: [{ required: true }]
          })(
            <Select onChange={e=>{
              this.setState({selectedPlatform: e})
            }}>
              {platforms.map(pf => <Select.Option key={pf.value}>{pf.title}</Select.Option>)}
            </Select>
          )}
        </Form.Item>
        {this.state.selectedPlatform !=="import" && <Form.Item label="Site Url">
          {getFieldDecorator('domain', {
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (validator.isURL(value, { protocols: ['https'] }) !== true) {
                    callback(new Error('Invalid Url. Url must start with https'))
                  }
                  return true
                }
              }
            ]
          })(
              <Input placeholder="https://" />
          )}
        </Form.Item>}

        {/* <Form.Item label="Connect to the site?" help="Select 'No' if you don't want to connect the site to POD app, use this site mode for importing order.">
          {getFieldDecorator('isConnect', {
            rules: [{ required: true }],
            initialValue: 1
          })(
            <Radio.Group name="radiogroup" onChange={e=>{
              if(e.target.value==0){
                this.setState({okButton: "Create"})
              }else{
                this.setState({okButton: "Connect"})
              }
            }}>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          )}
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>{this.state.selectedPlatform==="import"? "Create": "Connect"}</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'add_site_form' })(SiteAdd)
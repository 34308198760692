import React, {useEffect, useState} from "react";
import queryString from 'query-string'
import {useLocation} from "react-router-dom";
import gql from "../../api/gql";
import { Alert, Button, Spin } from 'antd';
import {Link} from "react-router-dom";

const AmzAuthorizeAddStore = () => {
    let { search } = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        setLoading(true);
        const {selling_partner_id, spapi_oauth_code, state} = queryString.parse(search);
        console.log(selling_partner_id, spapi_oauth_code, state);
        const query = `mutation createStoreByOAth($auth_code: String!, $state: String!, $seller_id: String!) {
          amzCreateStoreByOAuth(auth_code: $auth_code, 
            state: $state,
          seller_id: $seller_id)
        }`;
        gql.request(query, {
            auth_code: spapi_oauth_code,
            state: state,
            seller_id: selling_partner_id,
        }).then(res => {
            setSuccess(true);
            setLoading(false);

            setTimeout(() => {
                window.location = "/sites"
            }, 3000)
        }).catch(error => {
            setError(error[0].message);
            setLoading(false);

            setTimeout(() => {
                window.location = "/sites"
            }, 3000)
        })
    }, []);
    return (
        <>
            {success &&
                <div>
                    <Alert
                        message="Success"
                        description={"Site connection successful!!"}
                        type="success"
                        showIcon
                    />
                    <Spin spinning={loading} tip="Loading...">
                        <div style={{
                            height: 70,
                            width: 70,
                            margin: "auto"
                        }}>

                        </div>
                    </Spin>
                </div>
            }
            {error !== "" &&
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    showIcon
                />
                <Link to="/sites">
                    <Button style={{
                        marginTop: 5,
                        margin: "5px auto"
                    }} type="primary">Recreate</Button>
                </Link>
            </div>
            }
        </>
    )
}

export default AmzAuthorizeAddStore;
const origin = window.location.origin
export const MODE = 'dev'
export const API_URL ='https://api.oopprint.com'
export const APP_URL = (MODE === 'dev' && origin === 'http://localhost:3000') ? 'http://localhost:3000' : 'https://oopprint.com'
export const SHOPIFY_API = '8a7be891377ee5a50940961b7ecda3d2'
export const SHOPBASE_API = 'edced254db2b5f2e06213ff8775a9b61'
export const CLOUDFRONT_URL = 'https://d3lhymvx8v4hg2.cloudfront.net'
export const PAYPAL_ENV = "sandbox"//(MODE === 'dev' && origin === 'http://localhost:3000') ? 'sandbox' : 'production'; // you can set here to 'production' for production (sandbox)
export const CURRENCY = "USD"; // or you can set this value from your props or state
// Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/
export const PAYPAL_SANDBOX_KEY = "xxx"
export const PAYPAL_PRODUCTION_KEY = "xxx"
export const URL_API_UPLOAD_FILE = "https://m3uokktl3bctp5ms5rbpggifua0chhtj.lambda-url.ap-southeast-1.on.aws/upload-custom-font";
export const ID_SITE_WOO_BY_AMZ = 83
import React, {useState,useEffect} from "react";
import {Button, Modal, Select, message,Checkbox} from "antd";
import gql from "../../api/gql";

const SelectShippingCaseOrder = ({orderId, value, shipping_case_skip}) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] =useState(value);
    const [skipShippingCase, setSkipShippingCase] = useState(false);
    const { Option } = Select;
    const handleSelect = (value) => {
        setType(value)
    }

    const handleOk = async () => {
        setLoading(true);
        const mutation = `mutation shippingCaseOrder($order_id: Int!, $shipping_case: String!,$shipping_case_skip: Boolean!){
          shippingCaseOrder(order_id: $order_id, shipping_case: $shipping_case, shipping_case_skip: $shipping_case_skip){
            id
          }
        }`;

        gql.request(mutation, {
            order_id: orderId,
            shipping_case: type,
            shipping_case_skip: skipShippingCase
        }).then(res => {
            setLoading(false);
            setVisible(false);
        }).catch(function (error){
            message.error("An error occurred. Please try again!");
            setLoading(false);
        });
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }
    //Basic, Basic Plus, Premium, Premium Plus
    const options = [
        {
            label: "Basic",
            value: "Basic"
        },
        {
            label: "Basic Plus",
            value: "Basic Plus"
        },
        {
            label: "Premium",
            value: "Premium"
        },
        {
            label: "Premium Plus",
            value: "Premium Plus"
        }
    ]
    const onChangeSkipShippingCase = () => {
        setSkipShippingCase(!skipShippingCase);
    }
    useEffect(() => {
        setSkipShippingCase(shipping_case_skip);
    }, [])
    return (
        <>
            <Button style={{ width: "100%" }} icon="plus-circle" onClick={() => setVisible(true)}>Select Shipping Case</Button>
            <Modal
                title="Select shipping case order"
                visible={visible}
                onOk={handleOk}
                onCancel={() => {setVisible(false)}}
                footer={[
                    <Button key={"1"} onClick={() => setVisible(false)}>Cancel</Button>,
                    <Button key={"2"} onClick={handleOk} loading={loading} type="primary">Apply</Button>
                ]}
            >
                <div>
                    <Select defaultValue={value} style={{ width: "100%" }} onChange={handleSelect}>
                        {options.map((option, index) => <Option key={index} value={option.value}>{option.label}</Option>)}
                    </Select>
                    <Checkbox style={{
                        marginTop: 10
                    }} checked={skipShippingCase} onChange={onChangeSkipShippingCase}>Skip shipping case with State <b>(TX - CA - GA)</b></Checkbox>
                </div>
            </Modal>
        </>
    )
}

export default SelectShippingCaseOrder;

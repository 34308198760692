import React from 'react'
import { Skeleton, Card, Button, notification, Progress, Tooltip, Icon, message } from 'antd'
import Upload from '../Upload'
import gql from '../../api/gql'
import _ from 'lodash'
import PrintFilePreview from '../file/PrintFilePreview'
import styled from 'styled-components'
import { CLOUDFRONT_URL } from '../../config';

const Container = styled.div`
  .ant-upload.ant-upload-select.ant-upload-select-text{
    width:100%;
  }
  .pod-file-download{
    position: absolute;
    font-size: 14px;
    bottom: 12px;
    right: 8px;
    border-radius: 50%;
    background-color:#ff6666;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    i{
      color: #FFF;
    }
  }
  .branding_files{
    display: grid;
    grid-template-columns: repeat(12, auto);
    gap: 10px;
    margin-bottom:30px;
  }
  @media only screen and (max-width: 600px) {
    .branding_files{
      grid-template-columns: repeat(3, auto);
    }
  }
`
const UploadContainer = styled.div`
  position: relative;
  height:0;
  padding-bottom:67%;
  .upload-wrapper{
    display: block;
    position: relative;
    height: 0;
    padding-bottom:67%;
    .upload-area{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
class UploadImage extends React.Component {
  state = {
    file_id: this.props.file_id,
    uploading: false,
    percent: 0
  }
  componentDidMount() {
    console.log("this.props", this.props)
  }
  onUpload = file => {
    let query = `mutation createFile($input: NewFile!){
      createFile(input: $input){id}
    }`
    gql.request(query, {
      input: {
        source: 'aws',
        key: file.key,
        file_name: file.file.name,
        file_mime: file.file.type,
        file_size: file.file.size,
      }
    }).then(({ createFile }) => {
      const { file_id } = this.state
      this.setState({
        uploading: false,
        file: { ...file, file_id: createFile.id },
        file_id: createFile.id
      }, () => this.props.onChange(this.state.file))
    }).catch(err => {
      this.setState({
        uploading: false,
        percent: 0
      })
    })
  }

  beforeUpload = (file, fileList) => {
    var regex = new RegExp("^(\\d|\\w)+(.jpg|.jpeg|.png|.tiff|.tif|.psd)$")
    var result = regex.test(file.name);
    if (result === false) {
      message["error"]("File name cannot contain empty space or any special characters")
      return false
    } else {
      return true
    }
  }

  render() {
    const { file_id, uploading, percent } = this.state
    return (
      <UploadContainer>
        <Upload
          beforeUpload={this.beforeUpload}
          onUpload={this.onUpload}
          onProcess={e => {
            this.setState({
              uploading: true,
              percent: Math.round(e.loaded / e.total * 100)
            })
          }}
          fileList={false}
          accept=".jpg, .png, .tiff"
          className="upload-wrapper">
          <div className="upload-area">
            {uploading ?
              <Progress type="circle" percent={percent} strokeColor="#1890ff" width={50} /> :
              (file_id ? <PrintFilePreview file_id={file_id} /> : <span style={{ textAlign: 'center' }}>Missing, click to upload or drag to upload</span>)
            }
          </div>
        </Upload>
      </UploadContainer>
    )
  }
}

export default class BrandingSettings extends React.Component {
  state = {
    loading: true,
    percent: 0,
    submitLoading: false,
    uploadLoading: {},
    isReady: false,
    settings: {
      branding_packing_slip_front: "0",
      branding_packing_slip_back: "0",
      branding_insert_card_outside: "0",
      branding_insert_card_inside: "0",
      branding_sticker_front: "0",
      branding_custom_logo: "0",
      order_received_enable: false,
      order_received_after: 1,
      order_received_message: '',
      order_producing_enable: false,
      order_producing_message: '',
      order_shipping_enable: false,
      order_shipping_message: '',
      fulfill_role: false
    }
  }

  componentDidMount() {
    this.loadSiteSettings()
  }

  loadSiteSettings = () => {
    this.setState({ loading: true, isReady:false })
    const { site_id } = this.props
    let query = `query getSiteSettings{
      getSiteSettings(site_id: ${site_id}){
        branding_packing_slip_front
        branding_packing_slip_back
        branding_insert_card_outside
        branding_insert_card_inside
        branding_sticker_front
        branding_custom_logo
        order_received_enable
        order_received_after
        order_received_message
        order_producing_enable
        order_producing_message
        order_shipping_enable
        order_shipping_message
        fulfill_role
      }
    }`

    gql.request(query).then(res => {
      this.setState({
        settings: res.getSiteSettings,
        loading: false,
        isReady:true
      })
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { site_id } = this.props
    const { settings } = this.state
    const mutation = `mutation updateSiteSettings($site_id: Int!, $settings: SiteSettingsInput!){
      updateSiteSettings(site_id: $site_id, settings: $settings){
        branding_packing_slip_front
        branding_packing_slip_back
        branding_insert_card_outside
        branding_insert_card_inside
        branding_sticker_front
        branding_custom_logo
        order_received_enable
        order_received_after
        order_received_message
        order_producing_enable
        order_producing_message
        order_shipping_enable
        order_shipping_message
        fulfill_role
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, { site_id: site_id, settings: settings })
      .then(res => {
        this.setState({ loading: false })
        message["success"]("Site settings has been updated")
      })
      .catch(err => {
        this.setState({ loading: false })
        message["error"](_.get(err, "[0].message"))
      })
  }

  render() {
    const { loading, settings, isReady } = this.state
    console.log("settings123", settings)
    return (
      <Container>
        <h4>Branding Slip</h4>
        <div className="branding_files">
          <Card
            hoverable
            cover={(
              isReady && <UploadImage file_id={settings.branding_packing_slip_front} onChange={file => {
                this.setState({ settings: { ...settings, branding_packing_slip_front: file ? file.file_id : "" } })
              }} />
            )}
          >
            <Card.Meta title="Branding Packing Slip Front" />
          </Card>
          <Card
            hoverable
            cover={(
              isReady && <UploadImage file_id={settings.branding_packing_slip_back} onChange={file => {
                this.setState({ settings: { ...settings, branding_packing_slip_back: file ? file.file_id : "" } })
              }} />
            )}
          >
            <Card.Meta title="Branding Packing Slip Back" />
          </Card>
        </div>
        <h4>Insert Card</h4>
        <div className="branding_files">
          <Card
            hoverable
            cover={(
              isReady && <UploadImage file_id={settings.branding_insert_card_outside} onChange={file => {
                this.setState({ settings: { ...settings, branding_insert_card_outside: file ? file.file_id : "" } })
              }} />
            )}
          >
            <Card.Meta title="Branding Insert Card Outside" />
          </Card>
          <Card
            hoverable
            cover={(
              isReady && <UploadImage file_id={settings.branding_insert_card_inside} onChange={file => {
                this.setState({ settings: { ...settings, branding_insert_card_inside: file ? file.file_id : "" } })
              }} />
            )}
          >
            <Card.Meta title="Branding Insert Card Inside" />
          </Card>
        </div>
        <h4>Sticker</h4>
        <div className="branding_files">
          <Card
            hoverable
            cover={(
              isReady && <UploadImage file_id={settings.branding_sticker_front} onChange={file => {
                this.setState({ settings: { ...settings, branding_sticker_front: file ? file.file_id : "" } })
              }} />
            )}
          >
            <Card.Meta title="Branding Sticker Front" />
          </Card>
        </div>
        <h4>Custom Logo</h4>
        <div className="branding_files">
          <Card
            hoverable
            cover={(
              isReady && <UploadImage file_id={settings.branding_custom_logo} onChange={file => {
                this.setState({ settings: { ...settings, branding_custom_logo: file ? file.file_id : "" } })
              }} />
            )}
          >
            <Card.Meta title="Branding Custom Logo" />
          </Card>
        </div>
        <Button loading={loading} type="primary" onClick={this.onSubmit}>Save</Button>
      </Container >
    )
  }
}